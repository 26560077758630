<template>
  <Layout>
    <div class="private-chat-room">
      <v-progress-linear v-if="loading" color="primary" indeterminate />
      <div v-if="channel" class="chat-wrap">
        <ChatChannel :key="channel.sid" :active="true" :channel="channel" />
      </div>
      <v-row v-if="error" no-gutters>
        <div class="room-err">
          <template>
            <img class="room-err_img" src="@/assets/chat/registration_congratulations.svg" />
            <span class="room-err_text"> Log Into your Account. </span>
            <span class="room-err_text_small"> Chat room has finished. </span>
            <v-btn class="room-err_btn" color="primary" outlined @click="gotoLogin"> Log In</v-btn>
          </template>
        </div>
      </v-row>
    </div>
  </Layout>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ChatApi } from "@/api/chat";
import ChatChannel from "@/components/Chat/Channel/ChatChannel.vue";
import Layout from "@/components/Layout";
import { useAuthStore } from "@/pinia-store/auth";
import { useChatStore } from "@/pinia-store/chat";

import { snackBarEventBus, snackBarEventName } from "../../../eventBuses/snackBar.eventBus";

export default {
  components: {
    ChatChannel,
    Layout,
  },
  inject: ["injectable"],

  data() {
    return {
      channel: null,
      loading: true,
      error: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["isLoggedIn"]),
    ...mapState(useChatStore, [
      "loadingConversationParticipants",
      "floatingChatActiveTab",
      "getCurrentFloatingChannel",
    ]),
  },
  watch: {
    channel(val) {
      if (!val && !this.loading) this.error = true;
    },
    getCurrentFloatingChannel(val) {
      if (val?.interlocutor) {
        const obs = val.interlocutor.getChannelStatusObservable(val.sid);
        this.participantOnline = obs.getValue();
        this.participantStatusSubscription = obs.subscribe((val) => {
          this.participantOnline = val === "online";
        });
      }
    },
    async loadingConversationParticipants() {
      if (this.injectable.twilioContext) {
        this.channelsList = this.injectable.twilioContext.channelRepository.getTicketChannels();

        this.channel = this.channelsList.find((ch) => ch.sid === this.$route.params.channelSid);
        if (this.channel) {
          await this.injectable.twilioContext.setCurrentChannel(this.channel);
        } else {
          this.error = true;
        }
        this.loading = false;
        await ChatApi.notifyPractitioner(this.channel.sid, this.uid, "chatRoom");
      }
    },
    isLoggedIn(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.injectable.twilioContext.setCurrentFloatingChannel(null);
        if (this.timer) {
          clearTimeout(this.timer);
        }
      } else if (newVal === true && oldVal === false) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.injectable.twilioContext.setCurrentFloatingChannel(null);
      }
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["getCustomTokenChat", "signInWithCustomToken", "refreshToken"]),
    async initRoom() {
      this.error = null;

      const { channelSid, userId } = this.$route.params;
      this.loading = true;
      try {
        await this.getCustomTokenChat({ channelSid, userId });
        await this.signInWithCustomToken();
      } catch (e) {
        console.error(e);
        this.error = e.message || e;
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
    },
    gotoLogin() {
      this.$router.push("/auth/signin");
    },
  },
  async mounted() {
    await this.initRoom();
  },
};
</script>

<style lang="scss">
.private-chat-room {
  padding: 20px;

  .chat-wrap {
    border: 1px solid #d2d3e1;
    border-radius: 4px;

    > div {
      min-height: calc(100vh - 85px);

      > div {
        min-height: calc(100vh - 85px);
      }
    }
  }

  .room-err {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_img {
      margin-bottom: 2em;
    }

    &_text {
      margin-bottom: 0.3em;
      font-size: 28px;
      font-weight: 500;
      color: #33343e;
    }

    &_text_small {
      margin-bottom: 2em;
      font-size: 14px;
      font-weight: 400;
      color: #91909c;
    }

    &_btn {
      letter-spacing: normal;
      text-transform: initial;
    }
  }
}
</style>
